import React from "react";
import { Container, Row, Col, Form, Alert } from "react-bootstrap";
import { Formik } from "formik";
import {
  acceptOTP,
  handleGiftName,
} from "../../../../redux/features/customerSlice";
import styles from "./FourtyGift.module.scss";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import successMessage from "../../../messages/Successful";
import { toast, Zoom } from "react-toastify";
import failedMessage from "../../../messages/Failed";

export default function FourtyGift(props) {
  const gifts = props.vouchs;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const status = useSelector((state) => state.fit.customer.info.status);

  const trainerId = useSelector((state) => state.fit.customer.trainer.id);
  const mobileNumber = useSelector(
    (state) => state.fit.customer.info.mobileNumber
  );
  const storedOTP = useSelector((state) => state.fit.customer.info.otp);
  const giftValue = useSelector((state) => state.fit.customer.gift.value);
  const giftName = useSelector((state) => state.fit.customer.gift.name);

  return (
    <div>
      <div className={`${styles.giftNotes} p-2`}>
        <ul>
          <li>على مشتريات ب 600 جنية او أكتر من ماكس ماصل او أورجانيك نيشن</li>
          <li>هدية بقيمة 40 جنية تختار ها زي ماتحب من الهدايا دي :</li>
        </ul>
      </div>
      <div className={`${styles.services} p-2`}>
        <Formik
          initialValues={{
            giftName: "",
            giftValue: "",
            giftVendor: "",
            giftType: "",
          }}
        >
          {({ values, errors, handleChange, handleSubmit, setFieldValue }) => (
            <Form>
              <Container>
                <Row className="d-flex justify-content-center align-items-start">
                  {gifts.length !== 0 ? (
                    gifts.map((gift) => {
                      return (
                        <Col
                          xs={1}
                          className={`${styles.serviceContent}`}
                          key={Math.random() * 10000}
                        >
                          <input
                            hidden
                            type="radio"
                            id={gift.name}
                            name="fourtyGift"
                            className={`${styles.serviceInput}`}
                            onChange={(e) => {
                              setFieldValue("giftName", gift.name);
                              if (status === true) {
                                const loadReq = toast.loading("جاري التحميل", {
                                  transition: Zoom,
                                  position: "bottom-right",
                                });
                                dispatch(
                                  acceptOTP({
                                    otp: storedOTP.toString(),
                                    trainerId,
                                    mobileNumber,
                                    gValue: giftValue.toString(),
                                    giftName,
                                  })
                                ).then((res) => {
                                  if (res.payload.data.statusCode === 200) {
                                    successMessage(
                                      res.payload.data.message,
                                      res.payload.data.messageAr,
                                      loadReq
                                    );
                                    navigate("/Customer/Congrats");
                                  } else {
                                    failedMessage(
                                      res.payload.data.message,
                                      res.payload.data.messageAr,
                                      loadReq
                                    );
                                  }
                                });
                              } else {
                                dispatch(
                                  handleGiftName({
                                    giftName: gift.name,
                                    giftType: gift["gift type"],
                                    giftImage: gift["image url"],
                                  })
                                );
                                navigate("/Customer/Info");
                              }
                            }}
                            disabled={
                              gift.name !== "MAX MUSCLE EGP" ? true : false
                            }
                          />
                          <label
                            htmlFor={gift.name}
                            className={`${styles.serviceLabel}`}
                          >
                            <img
                              src={gift["image url"]}
                              alt="service-pic"
                              className={`${styles.servicePic}`}
                            />
                            <div className={`${styles.serviceText}`}>
                              {gift.name}
                            </div>
                          </label>
                        </Col>
                      );
                    })
                  ) : (
                    <Container>
                      <Row>
                        <Col xs={12}>جاري تحميل الهدايا ...</Col>
                      </Row>
                    </Container>
                  )}
                </Row>
              </Container>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
