import { toast } from "react-toastify";
import { Flip} from "react-toastify";



const failedMessage = (message, messageAr, pendingMessage) => {
  toast.update(pendingMessage, {
    render: messageAr,
    type: "error",
    position: "bottom-right",
    isLoading: false,
    transition: Flip,
    autoClose: 4000,
  });
};

export default failedMessage;
