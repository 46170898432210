import React, { useEffect, useState } from "react";
import { Modal, Form, Row, Col, Button } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import { MdOutlineMobileFriendly } from "react-icons/md";
import styles from "./OtpForm.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  acceptOTP,
  handleChooseGiftStatus,
  handleOTPCounter,
} from "../../../../redux/features/customerSlice";
import { useNavigate } from "react-router-dom";
import { toast, Zoom } from "react-toastify";
import successMessage from "../../../messages/Successful";
import failedMessage from "../../../messages/Failed";

export default function OtpForm(props) {
  const handleClickotp = (first, last) => {
    if (document.getElementById(first).value !== "") {
      document.getElementById(last).focus();
    }
  };
  const [confirm, setConfirm] = useState(false);
  const [otp, setOtp] = useState("");
  const dispatch = useDispatch();
  const trainerId = useSelector((state) => state.fit.customer.trainer.id);
  const mobileNumber = useSelector(
    (state) => state.fit.customer.info.mobileNumber
  );
  const storedOTP = useSelector((state) => state.fit.customer.info.otp);
  const count = useSelector((state) => state.fit.customer.info.count);
  const giftValue = useSelector((state) => state.fit.customer.gift.value);
  const giftName = useSelector((state) => state.fit.customer.gift.name);
  const navigate = useNavigate();

  const confirmOTP = (preOTP, nexOTP) => {
    if (preOTP === nexOTP) {
      dispatch(handleChooseGiftStatus());
      return true;
    } else {
      dispatch(
        handleOTPCounter({
          count,
        })
      );
    }
  };

  useEffect(() => {
    if (count >= 3) {
      navigate("/Customer/Info");
    }
  }, [count]);

  const convertNumbers2English = (string) => {
    return string
      .replace(/[\u0660-\u0669]/g, function (c) {
        return c.charCodeAt(0) - 0x0660;
      })
      .replace(/[\u06f0-\u06f9]/g, function (c) {
        return c.charCodeAt(0) - 0x06f0;
      });
  };

  const otpValidation = yup.object().shape({
    firstDigit: yup.string().required("كود OTP مطلوب"),
    secondDigit: yup.string().required("كود OTP مطلوب"),
    thirdDigit: yup.string().required("كود OTP مطلوب"),
    fourthDigit: yup.string().required("كود OTP مطلوب"),
    fifthDigit: yup.string().required("كود OTP مطلوب"),
    sixthDigit: yup.string().required("كود OTP مطلوب"),
  });

  return (
    <div>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        dir="ltr"
      >
        <Modal.Body className={`${styles.ModalBodyOTP}`}>
          <Formik
            initialValues={{
              firstDigit: "",
              secondDigit: "",
              thirdDigit: "",
              fourthDigit: "",
              fifthDigit: "",
              sixthDigit: "",
            }}
            onSubmit={(values, { resetForm }) => {
              var otpText = "";
              otpText += values.firstDigit;
              otpText += values.secondDigit;
              otpText += values.thirdDigit;
              otpText += values.fourthDigit;
              otpText += values.fifthDigit;
              otpText += values.sixthDigit;
              const otpStatus = confirmOTP(storedOTP, otpText);
              if (otpStatus === true) {
                const loadReq = toast.loading("جاري التحميل", {
                  transition: Zoom,
                  position: "bottom-right",
                });
                dispatch(
                  acceptOTP({
                    otp: storedOTP.toString(),
                    trainerId,
                    mobileNumber,
                    gValue: giftValue.toString(),
                    giftName,
                  })
                ).then((res) => {
                  if (res.payload.data.statusCode === 200) {
                    successMessage(
                      res.payload.data.message,
                      res.payload.data.messageAr,
                      loadReq
                    );
                  } else {
                    failedMessage(
                      res.payload.data.message,
                      res.payload.data.messageAr,
                      loadReq
                    );
                  }
                });
              } else {
                toast.error("كود OTP غير صحيح, حاول مرة اخرى", {
                  position: "bottom-right",
                  autoClose: 2000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: false,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                  closeButton: false,
                });
              }
              resetForm();
            }}
            validationSchema={otpValidation}
          >
            {({ values, errors, touched, handleChange, handleSubmit }) => (
              <Form onSubmit={handleSubmit} className="px-4">
                <Row>
                  <Col xs={12} dir="rtl" className="mb-3">
                    <MdOutlineMobileFriendly className="mx-1" />
                    رقم OTP :
                    <span className="d-block">
                      رقم التاكيد اللى جالك فى الرسالة
                    </span>
                  </Col>
                </Row>
                <Form.Group
                  as={Row}
                  className={`${styles.digitsParent} mb-3 p-3 d-flex justify-content-center`}
                >
                  <Col>
                    <Form.Control
                      type="tel"
                      maxLength={1}
                      min={1}
                      max={9}
                      className={`${styles.otpInput} ${
                        errors.firstDigit &&
                        touched.firstDigit &&
                        styles.errorInput
                      }`}
                      placeholder=""
                      size="sm"
                      name="firstDigit"
                      id="firstDigit"
                      onKeyPress={(e) => {
                        var char = String.fromCharCode(e.which);
                        if (!/[0-9]/.test(char)) {
                          e.preventDefault();
                        }
                      }}
                      onPaste={(e) => {
                        e.preventDefault();
                      }}
                      onCopy={(e) => {
                        e.preventDefault();
                      }}
                      onKeyUp={() => {
                        handleClickotp("firstDigit", "secondDigit");
                      }}
                      onChange={handleChange}
                      value={values.firstDigit}
                    />
                  </Col>
                  <Col>
                    <Form.Control
                      type="tel"
                      maxLength={1}
                      min={1}
                      max={9}
                      className={`${styles.otpInput} ${
                        errors.secondDigit &&
                        touched.secondDigit &&
                        styles.errorInput
                      }`}
                      placeholder=""
                      size="sm"
                      name="secondDigit"
                      id="secondDigit"
                      onKeyPress={(e) => {
                        var char = String.fromCharCode(e.which);
                        if (!/[0-9]/.test(char)) {
                          e.preventDefault();
                        }
                      }}
                      onPaste={(e) => {
                        e.preventDefault();
                      }}
                      onCopy={(e) => {
                        e.preventDefault();
                      }}
                      onKeyUp={() => {
                        handleClickotp("secondDigit", "thirdDigit");
                      }}
                      onChange={handleChange}
                      value={values.secondDigit}
                    />
                  </Col>
                  <Col>
                    <Form.Control
                      type="tel"
                      maxLength={1}
                      min={1}
                      max={9}
                      className={`${styles.otpInput} ${
                        errors.thirdDigit &&
                        touched.thirdDigit &&
                        styles.errorInput
                      }`}
                      placeholder=""
                      size="sm"
                      name="thirdDigit"
                      id="thirdDigit"
                      onKeyPress={(e) => {
                        var char = String.fromCharCode(e.which);
                        if (!/[0-9]/.test(char)) {
                          e.preventDefault();
                        }
                      }}
                      onPaste={(e) => {
                        e.preventDefault();
                      }}
                      onCopy={(e) => {
                        e.preventDefault();
                      }}
                      onKeyUp={() => {
                        handleClickotp("thirdDigit", "fourthDigit");
                      }}
                      onChange={handleChange}
                      value={values.thirdDigit}
                    />
                  </Col>
                  <Col>
                    <Form.Control
                      type="tel"
                      maxLength={1}
                      min={1}
                      max={9}
                      className={`${styles.otpInput} ${
                        errors.fourthDigit &&
                        touched.fourthDigit &&
                        styles.errorInput
                      }`}
                      placeholder=""
                      size="sm"
                      name="fourthDigit"
                      id="fourthDigit"
                      onKeyPress={(e) => {
                        var char = String.fromCharCode(e.which);
                        if (!/[0-9]/.test(char)) {
                          e.preventDefault();
                        }
                      }}
                      onPaste={(e) => {
                        e.preventDefault();
                      }}
                      onCopy={(e) => {
                        e.preventDefault();
                      }}
                      onKeyUp={() => {
                        handleClickotp("fourthDigit", "fifthDigit");
                      }}
                      onChange={handleChange}
                      value={values.fourthDigit}
                    />
                  </Col>
                  <Col>
                    <Form.Control
                      type="tel"
                      maxLength={1}
                      min={1}
                      max={9}
                      className={`${styles.otpInput} ${
                        errors.fifthDigit &&
                        touched.fifthDigit &&
                        styles.errorInput
                      }`}
                      placeholder=""
                      size="sm"
                      name="fifthDigit"
                      id="fifthDigit"
                      onKeyPress={(e) => {
                        var char = String.fromCharCode(e.which);
                        if (!/[0-9]/.test(char)) {
                          e.preventDefault();
                        }
                      }}
                      onPaste={(e) => {
                        e.preventDefault();
                      }}
                      onCopy={(e) => {
                        e.preventDefault();
                      }}
                      onKeyUp={() => {
                        handleClickotp("fifthDigit", "sixthDigit");
                      }}
                      onChange={handleChange}
                      value={values.fifthDigit}
                    />
                  </Col>
                  <Col>
                    <Form.Control
                      type="tel"
                      maxLength={1}
                      min={1}
                      max={9}
                      className={`${styles.otpInput} ${
                        errors.sixthDigit &&
                        touched.sixthDigit &&
                        styles.errorInput
                      }`}
                      placeholder=""
                      size="sm"
                      name="sixthDigit"
                      id="sixthDigit"
                      onKeyPress={(e) => {
                        var char = String.fromCharCode(e.which);
                        if (!/[0-9]/.test(char)) {
                          e.preventDefault();
                        }
                      }}
                      onPaste={(e) => {
                        e.preventDefault();
                      }}
                      onCopy={(e) => {
                        e.preventDefault();
                      }}
                      onChange={handleChange}
                      value={values.sixthDigit}
                    />
                  </Col>
                </Form.Group>

                <div className="d-grid">
                  <Button
                    variant="danger"
                    className={`${styles.nextBtn}`}
                    type="submit"
                  >
                    تمام .. الخطوة اللى بعدها
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </div>
  );
}
