import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import axios from "axios";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import { AiFillGift } from "react-icons/ai";
import FourtyGift from "./fourtyGift/FourtyGift";
import styles from "./Gift.module.scss";
import HunderedGift from "./hunderedGift/HunderedGift";
import { useDispatch } from "react-redux";
import { handleGiftValue } from "../../../redux/features/customerSlice";

export default function Gift() {
  const [giftValue, setGiftValue] = useState(null);
  const [vouchs, setVouchs] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (giftValue != null) {
      axios
        .get("https://hook.eu1.make.com/wjvdoaeks2jkid1ypfquh7hl6jr9llu2", {
          params: {
            "eGift Value": giftValue,
            Function: "geteGiftsList",
          },
        })
        .then((res) => {
          setVouchs(res.data["eGift cards list"]);
        });
    }
  }, [giftValue]);

  return (
    <div className={`${styles.chooseGift} p-4`}>
      <Formik
        initialValues={{ giftValue: 40 }}
        onSubmit={(values) => {
          dispatch(handleGiftValue({
            giftValue : values.giftValue
          }))
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          setFieldValue,
        }) => (
          <Form className="p-1 p-md-3" onSubmit={handleSubmit}>
            <Container>
              <Row>
                <Col xs={12} className="mb-3">
                  <Form.Label>اختار هديتك على قد مشترياتك</Form.Label>
                  <Container>
                    <Row
                      className={`${styles.giftContent} p-3 d-flex justify-content-around`}
                    >
                      <Col
                        xs={5}
                        sm={5}
                        md={4}
                        className={`${styles.giftElement} px-2 py-2`}
                      >
                        <input
                          hidden
                          type="radio"
                          className={styles.giftInput}
                          id="hunderedGift"
                          onChange={(e) => {
                            setGiftValue(100);
                            setFieldValue("giftValue", 100);
                            handleSubmit();
                          }}
                          checked={giftValue === 100 ? true : false}
                        />
                        <label htmlFor="hunderedGift">
                          <div className={styles.giftIconStyle}>
                            <AiFillGift className="mx-1" />
                            هدية
                          </div>
                          <div>
                            <span className={`${styles.fontNum} mx-1`}>
                              100
                            </span>
                            EGP
                          </div>
                        </label>
                      </Col>
                      <Col
                        xs={5}
                        sm={5}
                        md={4}
                        className={`${styles.giftElement} px-2 py-2`}
                      >
                        <input
                          hidden
                          className={styles.giftInput}
                          type="radio"
                          id="fourtyGift"
                          onChange={(e) => {
                            setGiftValue(40);
                            setFieldValue("giftValue", 40);
                            handleSubmit();
                          }}
                          checked={giftValue === 40 ? true : false}
                        />
                        <label htmlFor="fourtyGift">
                          <div className={styles.giftIconStyle}>
                            <AiFillGift className="mx-1" />
                            هدية
                          </div>
                          <div>
                            <span className={`${styles.fontNum} mx-1`}>40</span>
                            EGP
                          </div>
                        </label>
                      </Col>
                    </Row>
                  </Container>
                </Col>
              </Row>
            </Container>
          </Form>
        )}
      </Formik>
      {giftValue != null && (
        <Container>
          <Row>
            <Col xs={12} className={`${styles.giftContent} p-4`}>
              <div className="mb-4">
                <AiFillGift className="mx-1" />
                <span className={`${styles.giftChooseValue} px-3 py-2`}>
                  {giftValue}
                  <span className="mx-1">EGP</span>
                </span>
              </div>
              {giftValue === 100 ? (
                <HunderedGift vouchs={vouchs} />
              ) : giftValue === 40 ? (
                <FourtyGift vouchs={vouchs} />
              ) : null}
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
}
