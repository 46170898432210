import React, { useState } from "react";
import { Modal, Button, Form, Container, Row, Col } from "react-bootstrap";
import { BsCameraFill } from "react-icons/bs";
import { IoCheckmarkDoneSharp } from "react-icons/io5";
import { RiImageAddFill } from "react-icons/ri";
import "cropperjs/dist/cropper.css";
import { FaReceipt } from "react-icons/fa";
import styles from "./Capture.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { handleCaptureReceipt } from "../../../../redux/features/customerSlice";
import moment from "moment";

export default function Capture(props) {
  const [image, setImage] = useState("");

  const dispatch = useDispatch();

  const handleChange = (e) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
    };
    reader.readAsDataURL(files[0]);
  };

  return (
    <div>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          {image === "" ? (
            <div className={`${styles.emptyField}`}>
              <FaReceipt />
            </div>
          ) : (
            <img
              src={image}
              alt="receipt-pic"
              className={styles.receiptPhoto}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Container>
            <Row>
              <Col xs={12} md={12} className="d-grid px-2 mb-2">
                <label
                  htmlFor="crop-image"
                  className={`${styles.uploadBtn} btn btn-sm btn-dark`}
                >
                  <RiImageAddFill className="mx-1" />
                  تحميل صورة الفاتورة
                </label>
                <input
                  hidden
                  id="crop-image"
                  type="file"
                  onChange={handleChange}
                />
              </Col>
              <Col xs={12} className="d-grid px-2">
                <Button
                  variant="success"
                  size="sm"
                  className={`${styles.captureBtn}`}
                  onClick={() => {
                    dispatch(
                      handleCaptureReceipt({
                        capturedImage: image,
                      })
                    )
                    props.onHide();
                  }}
                  disabled={image !== "" ? false : true}
                >
                  <IoCheckmarkDoneSharp className="mx-1" />
                  تمام
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
