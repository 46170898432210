import React from 'react'
import { Navigate } from 'react-router-dom'

export default function CustomerPrivateRoute({condition,children}) {


    if(condition === ""){
        return <Navigate to="/Find/Trainer"/>
    }else{
        return children;
    }
}
