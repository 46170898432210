import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Formik } from "formik";
import * as yup from "yup";
import { FaUserAlt } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { BsGenderAmbiguous, BsFillTelephoneFill } from "react-icons/bs";
import styles from "./Info.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { customerInfo } from "../../../redux/features/customerSlice";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { toast, Zoom } from "react-toastify";
import successMessage from "../../messages/Successful";
import failedMessage from "../../messages/Failed";

export default function Info() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const storedMobileNumber = useSelector(
    (state) => state.fit.customer.info.mobileNumber
  );
  const storedFirstName = Cookies.get("first-name");
  const storedLastName = Cookies.get("last-name");
  const storedGender = Cookies.get("gender");
  const storedEmail = Cookies.get("email");

  const customerInfoValidation = yup.object().shape({
    firstName: yup
      .string()
      .required("الاسم الاول مطلوب")
      .matches(
        /^((?![0-9.,!?:;_|+\-*\\/=%°@&#§$"'`¨^ˇ()\[\]<>{}])[\S])+$/gm,
        "الاسم غير صحيح"
      ).matches(/^[a-zA-Z]*$/,"الاسم بالانجليزي فقط"),
    lastName: yup
      .string()
      .required("الاسم الاخير مطلوب")
      .matches(
        /^((?![0-9.,!?:;_|+\-*\\/=%°@&#§$"'`¨^ˇ()\[\]<>{}])[\S])+$/gm,
        "الاسم غير صحيح"
      ).matches(/^[a-zA-Z]*$/,"الاسم بالانجليزي فقط"),
    email: yup
      .string()
      .email("البريد الالكتروني غير صحيح")
      .required("البريد الالكتروني مطلوب"),
    gender: yup.string().required("النوع مطلوب"),
    mobileNumber: yup
      .string()
      .required("رقم الموبايل مطلوب")
      .matches(/^01[0125][0-9]{8}$/gm, "رقم الموبايل غير صحيح"),
  });

  const convertNumbers2English = (string) => {
    return string
      .replace(/[\u0660-\u0669]/g, function (c) {
        return c.charCodeAt(0) - 0x0660;
      })
      .replace(/[\u06f0-\u06f9]/g, function (c) {
        return c.charCodeAt(0) - 0x06f0;
      });
  };


  return (
    <div className={`${styles.infoContent} p-3`}>
      <div className={`${styles.customerInfo} p-3`}>
        <Formik
          initialValues={{
            firstName: storedFirstName ? storedFirstName : "",
            lastName: storedLastName ? storedLastName : "",
            gender: "",
            mobileNumber: storedMobileNumber ? storedMobileNumber : "",
            email: storedEmail ? storedEmail : "",
          }}
          onSubmit={(values) => {
            const mobileNumberEn = convertNumbers2English(values.mobileNumber);
            const loadReq = toast.loading("جاري التحميل", {
              transition: Zoom,
              position: "bottom-right",
            });
            dispatch(
              customerInfo({
                firstName: values.firstName,
                lastName: values.lastName,
                gender: values.gender,
                mobileNumber: mobileNumberEn,
                email: values.email,
                count: 0,
              })
            ).then((res) => {
              if (res.payload.data.statusCode === 200) {
                successMessage(
                  res.payload.data.message,
                  res.payload.data.messageAr,
                  loadReq
                );
                Cookies.set("first-name", values.firstName);
                Cookies.set("last-name", values.lastName);
                Cookies.set("gender", values.gender);
                Cookies.set("mobile-number", mobileNumberEn);
                Cookies.set("email", values.email);
                navigate("/Customer/Congrats");
              } else {
                failedMessage(
                  res.payload.data.message,
                  res.payload.data.messageAr,
                  loadReq
                );
              }
            });
          }}
          validationSchema={customerInfoValidation}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            setFieldValue,
            isSubmitting
          }) => (
            <Form onSubmit={handleSubmit}>
              <Container>
                <Form.Group
                  as={Row}
                  className={`d-flex justify-content-start mb-3 p-3 ${styles.bgWhite}`}
                >
                  <Form.Label column sm={3}>
                    <FaUserAlt className="mx-2" />
                    الاسم:
                  </Form.Label>
                  <Col sm={4} className="mb-2 mb-md-2">
                    <Form.Control
                      type="text"
                      className={styles.customerInput}
                      placeholder=""
                      name="firstName"
                      id="firstName"
                      onChange={handleChange}
                      value={values.firstName}
                    />
                    {errors.firstName && touched.firstName && (
                      <Form.Text className="text-error">
                        {errors.firstName}
                      </Form.Text>
                    )}
                  </Col>
                  <Col sm={4}>
                    <Form.Control
                      type="text"
                      className={styles.customerInput}
                      placeholder=""
                      name="lastName"
                      id="lastName"
                      onChange={handleChange}
                      value={values.lastName}
                    />
                    {errors.lastName && touched.lastName && (
                      <Form.Text className="text-error">
                        {errors.lastName}
                      </Form.Text>
                    )}
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  className={`d-flex justify-content-start mb-3 p-3 ${styles.bgWhite}`}
                >
                  <Form.Label column sm={3}>
                    <MdEmail className="mx-2" />
                    البريد الالكتروني:
                  </Form.Label>
                  <Col sm={8}>
                    <Form.Control
                      type="text"
                      className={styles.emailInput}
                      placeholder=""
                      name="email"
                      id="email"
                      onChange={handleChange}
                      value={values.email}
                    />
                    {errors.email && touched.email && (
                      <Form.Text className="text-error">
                        {errors.email}
                      </Form.Text>
                    )}
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  className={`d-flex justify-content-start p-3 ${styles.bgWhite} mb-3`}
                >
                  <Form.Label column sm={3}>
                    <BsGenderAmbiguous className="mx-2" />
                    النوع:
                  </Form.Label>
                  <Col sm={6}>
                    <Container>
                      <Row>
                        <Col className="mb-2 mb-lg-0">
                          <input
                            hidden
                            type="radio"
                            name="gender"
                            id="male"
                            className={`${styles.genderInput}`}
                            onChange={(e) => {
                              if (e.target.checked === true) {
                                setFieldValue("gender", "male");
                              }
                            }}
                          />
                          <label
                            htmlFor="male"
                            className={`${styles.genderLabel} py-1`}
                          >
                            <span>ذكر</span>
                            <img
                              src={require("../../../imgs/Male.png")}
                              alt="male-pic"
                              className={`${styles.genderIcon}`}
                            />
                          </label>
                        </Col>
                        <Col>
                          <input
                            hidden
                            type="radio"
                            name="gender"
                            id="female"
                            className={`${styles.genderInput}`}
                            onChange={(e) => {
                              if (e.target.checked === true) {
                                setFieldValue("gender", "female");
                              }
                            }}
                          />
                          <label
                            htmlFor="female"
                            className={`${styles.genderLabel} py-1`}
                          >
                            <span>انثى</span>
                            <img
                              src={require("../../../imgs/Female.png")}
                              alt="male-pic"
                              className={`${styles.genderIcon}`}
                            />
                          </label>
                        </Col>
                        {errors.gender && touched.gender && (
                          <Form.Text className="text-error">
                            {errors.gender}
                          </Form.Text>
                        )}
                      </Row>
                    </Container>
                  </Col>
                </Form.Group>
                <Form.Group
                  as={Row}
                  className={`d-flex justify-content-start mb-3 p-3 ${styles.bgWhite}`}
                >
                  <Form.Label column sm={5}>
                    <BsFillTelephoneFill className="mx-2" />
                    رقم التليفون:
                    <span className="d-block">
                      الرقم اللى حابب تستلم عليه كود الهدية
                    </span>
                  </Form.Label>
                  <Col sm={6}>
                    <Form.Control
                      type="tel"
                      maxLength={11}
                      className={styles.customerInput}
                      placeholder=""
                      name="mobileNumber"
                      id="mobileNumber"
                      onChange={handleChange}
                      value={values.mobileNumber}
                      onKeyPress={(e) => {
                        var char = String.fromCharCode(e.which);
                        if (!/[0-9]/.test(char)) {
                          e.preventDefault();
                        }
                      }}
                      onPaste={(e) => {
                        e.preventDefault();
                      }}
                      onCopy={(e) => {
                        e.preventDefault();
                      }}
                      disabled={storedMobileNumber !== "" ? true : false}
                    />
                    {errors.mobileNumber && touched.mobileNumber && (
                      <Form.Text className="text-error">
                        {errors.mobileNumber}
                      </Form.Text>
                    )}
                  </Col>
                </Form.Group>
                <Col xs={12} md={{ span: 4, offset: 4 }}>
                  <div className="d-grid">
                    <Button
                      type="submit"
                      variant="danger"
                      className={`${styles.submitBtn}`}
                      disabled={isSubmitting}
                    >
                      تاكيد البيانات
                    </Button>
                  </div>
                </Col>
              </Container>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
