import React from "react";
import { Container, Row, Col, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import styles from "./Layout.module.scss";

export default function Layout(props) {
  const elmentBody = props.bodyelement;

  const trainerImage = sessionStorage.getItem("Timage");
  const trainerImagee = useSelector((state) => state.fit.customer.trainer.img);

  return (
    <div
      className={`${styles.layout}`}
      style={{
        backgroundImage: `url(${trainerImagee ? trainerImagee : trainerImage})`,
        backgroundSize: "cover",
        backgroundPosition: "top center",
        backgroundRepeat: "no-repeat",
        backgroundBlendMode: "multiply",
      }}
    >
      {!trainerImagee && (
        <div className={`${styles.loadPage}`}>
          <Container>
            <Row>
              <Col xs={12}>
                <h2 className="mb-4">جاري التحميل ... استني شوية</h2>
              </Col>
              <Col xs={12}>
                <Spinner animation="border" role="status" variant="light">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </Col>
            </Row>
          </Container>
        </div>
      )}

      <div className="w-100">
        <Container>
          <Row>
            <Col xs={12} className={`${styles.layoutHeader} px-2 pt-2`}>
              <img
                src={require("../../../imgs/Fit-rewards-white-logo.png")}
                alt="fit-rewards-white"
                className={styles.fitRewardsLogo}
              />
            </Col>
            <Col
              xs={12}
              md={{ span: 10, offset: 1 }}
              className={`${styles.layoutBody}`}
            >
              {elmentBody}
            </Col>
            <Col xs={12} className={`${styles.layoutFooter} p-4`}>
              <img
                src={require("../../../imgs/Powered by white.png")}
                alt="shareecoin-logo-transparent"
                className={styles.shareecoinLogoWhite}
              />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
