import React, { useEffect } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { clearResults } from "../../../redux/features/customerSlice";
import { Formik } from "formik";
import * as yup from "yup";
import Cookies from "js-cookie";
import styles from "./Search.module.scss";
import { useNavigate } from "react-router-dom";

export default function Search() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(clearResults());
    Cookies.remove("first-name");
    Cookies.remove("last-name");
    Cookies.remove("email");
    Cookies.remove("mobile-number");
    Cookies.remove("gender");
  }, []);

  const trainerNameValidation = yup.object().shape({
    trainerName: yup
      .string()
      .required("اسم المدرب مطلوب")
      .matches(/^[A-Z][a-zA-Z '.-]*[A-Za-z][^-]$/, "الاسم غير صحيح"),
  });

  return (
    <div className={`${styles.searchTrainer}`}>
      <Container>
        <Row>
          <Col xs={12} md={12} className="text-center">
            <img
              src={require("../../../imgs/search.png")}
              alt="search-trainer"
              className={`${styles.searchChar} animate__animated animate__headShake animate__slow animate__infinite	`}
            />
          </Col>
          <Col
            xs={12}
            md={12}
            className="d-flex justify-content-center align-items-center flex-column"
          >
            <h3 className="text-center mb-3">دور على مدربك .. و خد هديتك</h3>
            <Formik
              initialValues={{ trainerName: "" }}
              onSubmit={(values) => {
                navigate(`/${values.trainerName}`);
              }}
              validationSchema={trainerNameValidation}
            >
              {({ values, errors, handleSubmit, handleChange, touched }) => (
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col xs={12} md={{ span: 10, offset: 1 }}>
                      <Form.Control
                        type="text"
                        className={`${styles.inputField}`}
                        placeholder="اسم المدرب الخاص بك"
                        name="trainerName"
                        id="trainerName"
                        onChange={handleChange}
                        value={values.trainerName}
                      />
                      {errors.trainerName && touched.trainerName && (
                        <Form.Text className="text-error">
                          {errors.trainerName}
                        </Form.Text>
                      )}
                    </Col>
                    <Col xs={12} md={{ span: 6, offset: 3 }} className="mt-3">
                      <Button
                        type="submit"
                        variant="danger"
                        size="sm"
                        className={`${styles.searchBtn} w-100`}
                      >
                        تمام .. هات اللينك
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col xs={12} className={`${styles.layoutFooter} p-4`}>
            <img
              src={require("../../../imgs/Powered by.png")}
              alt="shareecoin-logo-transparent"
              className={styles.shareecoinLogoWhite}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}
