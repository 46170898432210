import React from "react";
import { Container, Col, Row, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { generateHashcode } from "../../../redux/features/customerSlice";
import styles from "./Barcode.module.scss";

export default function Barcode() {
  const giftCode = useSelector((state) => state.fit.customer.gift.barCode);
  const hashCode = useSelector((state) => state.fit.customer.gift.hashCode);
  const giftValue = useSelector((state) => state.fit.customer.gift.value);

  const mobileNumber = useSelector(
    (state) => state.fit.customer.info.mobileNumber
  );

  return (
    <div
      className={`${styles.giftsContent} d-flex justify-content-center align-items-center p-4`}
    >
      <Container>
        <Row>
          <Col
            xs={12}
            md={{ span: 8, offset: 2 }}
            className={`${styles.barcodeContent} p-3 mb-3`}
          >
            <Container>
              <Row>
                <Col xs={12} className="text-center mb-4">
                  <h3 className="text-center">مبروك الهدية</h3>
                  <p className="text-center mb-4">
                    خصم {giftValue} جنيه على مشترياتك من ماكس ماصل بدون حد ادني
                  </p>
                  <h5 dir="ltr" className={`${styles.barcodeTitle}`}>
                    1- Customer Code
                  </h5>
                  {hashCode !== "" ? (
                    <img
                      src={`data:image/png;base64,${hashCode}`}
                      alt="barcod-gift"
                      className={`${styles.barcodeGift}`}
                    />
                  ) : (
                    <h4>جاري تحميل رمز التجزئة</h4>
                  )}
                </Col>
                <Col xs={12} className="text-center">
                  <h5 dir="ltr" className={`${styles.barcodeTitle}`}>
                    2- e-Gift Coupon
                  </h5>
                  <img
                    src={`data:image/png;base64,${giftCode}`}
                    alt="barcod-gift"
                    className={`${styles.barcodeGift}`}
                  />
                </Col>
              </Row>
            </Container>
          </Col>
          <Col xs={12} className={`${styles.warningModalContent} mb-3 p-1`}>
            <h6>
              <img
                src={require("../../../imgs/warning.png")}
                alt="warning-pic"
                className={`${styles.warningIcon} mx-2`}
              />
              شروط صرف الجائزة:
            </h6>
            <ul>
              <li>
                <Container>
                  <Row>
                    <Col xs={12} sm={6}>
                      <span className={`${styles.numberElement}`}>1- </span>
                      من دلوقتي ولمدة 10 أيام. زور أي فرع من فروع ماكس ماصل
                      وأشتري منتجات بفاتورة {giftValue === 40
                        ? "600"
                        : "1300"}{" "}
                      او أكثر
                    </Col>
                    <Col xs={12} sm={6}>
                      <img
                        className="p-3 img-responsive"
                        src={require("../../../imgs/Supplements.png")}
                        alt="supply-pic"
                      />
                    </Col>
                  </Row>
                </Container>
              </li>
              <li>
                <Container>
                  <Row className="d-flex flex-column-reverse flex-sm-row">
                    <Col xs={12} sm={6}>
                      <img
                        className="p-3 img-responsive"
                        src={require("../../../imgs/Max step 2 - redeem.png")}
                        alt="redeem-pic"
                      />
                    </Col>
                    <Col xs={12} sm={6}>
                      <span className={`${styles.numberElement}`}>2- </span>
                      مهم..علشان تاخد جايزتك لازم تطلب من الكاشير تسجيل فاتورتك
                      في برنامج نقاط الولاء بتاعهم (برنامج شاري كوين)
                    </Col>
                  </Row>
                </Container>
              </li>
              <li>
                <Container>
                  <Row>
                    <Col xs={12} sm={6}>
                      <span className={`${styles.numberElement}`}>3- </span>
                      بعد عملية تسجيل النقاط صور الفاتورة وارفعها وفي خلال ثواني
                      هايكون تم التأكد من العملية وهيوصلك كود الهدية
                    </Col>
                    <Col xs={12} sm={6}>
                      <img
                        className="p-3 img-responsive"
                        src={require("../../../imgs/Coupon.png")}
                        alt="coupon-pic"
                      />
                    </Col>
                  </Row>
                </Container>
              </li>
            </ul>
          </Col>
          <Col xs={12} md={{ span: 6, offset: 3 }}>
            <div className="w-100">
              <Link
                to={`//m.me/ShareeCoin`}
                target="_blank"
                rel="noopener noreferrer"
                className={`${styles.okBtn} w-100 btn btn-sm btn-secondary`}
              >
                الدعم الفني
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
