import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.rtl.min.css";
import "animate.css";
import "react-toastify/dist/ReactToastify.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Layout from "../src/components/customer/layout/Layout";
import { ToastContainer } from "react-toastify";
import Gift from "./components/customer/gift/Gift";
import Home from "./components/customer/home/Home";
import Info from "./components/customer/info/Info";
import Congrats from "./components/customer/congrat/Congrats";
import Login from "./components/trainer/login/Login";
import Dashboard from "./components/trainer/dashboard/Dashboard";
import CustomerPrivateRoute from "./routes/CustomerPrivateRoute";
import { useSelector } from "react-redux";
import Barcode from "./components/customer/barcode/Barcode";
import Search from "./components/customer/search/Search";
import "./App.css";

function App() {
  const trianerID = useSelector((state) => state.fit.customer.trainer.id);
  const giftValue = useSelector((state) => state.fit.customer.gift.value);
  const otp = useSelector((state) => state.fit.customer.info.otp);
  const barcode = useSelector((state) => state.fit.customer.gift.barCode);


  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Navigate to="/Find/Trainer" />}></Route>
          <Route path="/*" element={<Navigate to="/Find/Trainer" />}></Route>
          <Route
            path="/:trainerName"
            element={<Layout bodyelement={<Home />} />}
          ></Route>
          <Route
            path="/Customer/Choose"
            element={
              <CustomerPrivateRoute condition={trianerID}>
                <Layout bodyelement={<Gift />} />
              </CustomerPrivateRoute>
            }
          ></Route>
          <Route
            path="/Customer/Info"
            element={
              <CustomerPrivateRoute condition={giftValue}>
                <Layout bodyelement={<Info />} />
              </CustomerPrivateRoute>
            }
          ></Route>
          <Route
            path="/Customer/Congrats"
            element={
              <CustomerPrivateRoute condition={otp}>
                <Layout bodyelement={<Congrats />} />
              </CustomerPrivateRoute>
            }
          ></Route>
          <Route
            path="/Customer/Gifts"
            element={
              <CustomerPrivateRoute condition={barcode}>
                <Layout bodyelement={<Barcode />} />
              </CustomerPrivateRoute>
            }
          ></Route>
          <Route path="/Find/Trainer" element={<Search />}></Route>
        </Routes>
        <ToastContainer rtl className="toast-style" pauseOnFocusLoss={false} />
      </div>
    </Router>
  );
}

export default App;
