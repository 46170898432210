import React, { useEffect, useState } from "react";
import { Button, Container, Row, Col, Form } from "react-bootstrap";
import { IoReceiptSharp } from "react-icons/io5";
import { MdOutlinePhotoLibrary } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Capture from "./capture/Capture";
import OtpForm from "./otp/OtpForm";
import { Link } from "react-router-dom";
import styles from "./Congrats.module.scss";
import { Formik } from "formik";
import * as yup from "yup";
import {
  changeGift,
  handleReceiptFileImage,
  verifyReceipt,
  generateHashcode,
} from "../../../redux/features/customerSlice";
import moment from "moment";
import { toast, Zoom } from "react-toastify";
import successMessage from "../../messages/Successful";
import failedMessage from "../../messages/Failed";

export default function Congrats() {
  const [captureModalShow, setCaptureModalShow] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);

  const croppedImage = useSelector(
    (state) => state.fit.customer.receipt.receiptCapturedImage
  );
  const mobileNumber = useSelector(
    (state) => state.fit.customer.info.mobileNumber
  );
  const giftName = useSelector((state) => state.fit.customer.gift.name);
  const giftValue = useSelector((state) => state.fit.customer.gift.value);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const status = useSelector((state) => state.fit.customer.info.status);
  const giftImage = useSelector((state) => state.fit.customer.gift.image);

  useEffect(() => {
    if (status === true) {
      setShowOtpModal(false);
    } else {
      setShowOtpModal(true);
    }
  });

  const dataURLtoFile = (dataurl, filename) => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  const trainerId = useSelector((state) => state.fit.customer.trainer.id);
  const dateNow = moment().format("DDMMYYYY");
  const timeNow = moment().format("hmm");

  useEffect(() => {
    if (croppedImage !== "") {
      const imageFile = dataURLtoFile(
        croppedImage,
        `maxmuscle_${trainerId}_${mobileNumber}_${dateNow}_${timeNow}}`
      );
      dispatch(
        handleReceiptFileImage({
          receiptFile: imageFile,
        })
      );
    }
  }, [croppedImage]);

  const receiptFile = useSelector(
    (state) => state.fit.customer.receipt.ReceiptFileImage
  );

  return (
    <div className={`${styles.congratsContent} p-3`}>
      <div>
        <img
          src={require("../../../imgs/gift.png")}
          alt="gift-pic"
          className={styles.giftPic}
        />
      </div>
      <Container>
        <Row>
          <Col
            xs={12}
            md={{ span: 6, offset: 3 }}
            className={`${styles.giftMessage} p-4 mb-3`}
          >
            <h5>مبروك الهدية</h5>
            <p>{giftName}</p>
            <p dir="ltr">{giftValue} EGP</p>
            <img
              src={giftImage}
              alt="giftPic-Congrat"
              className={`${styles.giftCongrats}`}
            />
            <div className="w-100 text-center">
              <Button
                variant="danger"
                className={`${styles.changeGift}`}
                onClick={() => {
                  const loadReq = toast.loading("جاري التحميل", {
                    transition: Zoom,
                    position: "bottom-right",
                  });
                  dispatch(
                    changeGift({
                      giftName,
                      gValue: giftValue.toString(),
                      mobileNumber,
                    })
                  ).then((res) => {
                    if (res.payload.data.statusCode === 200) {
                      successMessage(
                        res.payload.data.message,
                        res.payload.data.messageAr,
                        loadReq
                      );
                      navigate("/Customer/Choose");
                    } else {
                      failedMessage(
                        res.payload.data.message,
                        res.payload.data.messageAr,
                        loadReq
                      );
                    }
                  });
                }}
              >
                تغيير الهدية
              </Button>
            </div>
          </Col>
          <Col xs={12} className={`${styles.warningModalContent} mb-3 p-1`}>
            <h6>
              <img
                src={require("../../../imgs/warning.png")}
                alt="warning-pic"
                className={`${styles.warningIcon} mx-2`}
              />
              شروط الحصول على الجائزة:
            </h6>
            <ul>
              <li>
                <Container>
                  <Row>
                    <Col xs={12} sm={6}>
                      <span className={`${styles.numberElement}`}>1- </span>
                      من دلوقتي ولمدة 10 أيام. زور أي فرع من فروع ماكس ماصل
                      وأشتري منتجات بفاتورة {giftValue === 40
                        ? "600"
                        : "1300"}{" "}
                      او أكثر
                    </Col>
                    <Col xs={12} sm={6}>
                      <img
                        className="p-3 img-responsive"
                        src={require("../../../imgs/Supplements.png")}
                        alt="supply-pic"
                      />
                    </Col>
                  </Row>
                </Container>
              </li>
              <li>
                <Container>
                  <Row className="d-flex flex-column-reverse flex-sm-row">
                    <Col xs={12} sm={6}>
                      <img
                        className="p-3 img-responsive"
                        src={require("../../../imgs/Max step 2 - redeem.png")}
                        alt="redeem-pic"
                      />
                    </Col>
                    <Col xs={12} sm={6}>
                      <span className={`${styles.numberElement}`}>2- </span>
                      مهم..أطلب من الكاشير تسجيل رقمك في برنامج نقاط الولاء
                      الخاص ب( شاري كوين)
                    </Col>
                  </Row>
                </Container>
              </li>
              <li>
                <Container>
                  <Row>
                    <Col xs={12} sm={6}>
                      <span className={`${styles.numberElement}`}>3- </span>
                      صور الايصال بعد تسجيل النقاط في المحل وارفعه وفي خلال 10
                      دقائق هايكون تم التأكد من العملية وهيوصلك كود الهدية
                    </Col>
                    <Col xs={12} sm={6}>
                      <img
                        className="p-3 img-responsive"
                        src={require("../../../imgs/Capture receipt.png")}
                        alt="coupon-pic"
                      />
                    </Col>
                  </Row>
                </Container>
              </li>
            </ul>
          </Col>
          <Col xs={12} className={`${styles.captureReceipt}`}>
            <Container>
              <Row className="d-flex flex-column-reverse flex-sm-column-reverse flex-md-column-reverse flex-lg-row">
                <Col xs={12} lg={6}>
                  <Form>
                    <Form.Group>
                      <Form.Label>
                        <IoReceiptSharp className="mx-2" />
                        صورة الفاتورة :
                      </Form.Label>
                      <div className="d-grid">
                        <Button
                          variant="dark"
                          size="sm"
                          onClick={() => {
                            setCaptureModalShow(true);
                          }}
                        >
                          صور الفاتورة
                        </Button>
                      </div>
                    </Form.Group>
                  </Form>
                  <div className={`${styles.receiptNotes} p-2`}>
                    <ul>
                      <li>
                        صورة الفاتورة لازم تكون كاملة و يبان فيها
                        <span>كود الاوردر - التاريخ - قيمة الفاتورة</span>
                        علشان تاخد هديتك, لو مش واضحة صورها تان
                      </li>
                      <li>
                        صورة الفاتورة لازم تكون بعد
                        <span>تسجيلها في برنامج نقاط الولاء شاري كوين</span>
                      </li>
                    </ul>
                  </div>
                  <div className={`${styles.sendBtns} mb-2`}>
                    <Container>
                      <Row className="">
                        <Col xs={12} md={6}>
                          <Formik
                            initialValues={{ receiptFile: "" }}
                            onSubmit={(values) => {
                              const loadReq = toast.loading("جاري التحميل", {
                                transition: Zoom,
                                position: "bottom-right",
                              });
                              dispatch(
                                verifyReceipt({
                                  receiptFile,
                                  mobileNumber,
                                  giftName,
                                  giftValue,
                                })
                              ).then((res) => {
                                if (res.payload.data.statusCode === 403) {
                                  failedMessage(
                                    res.payload.data.message,
                                    res.payload.data.messageAr,
                                    loadReq
                                  );
                                } else {
                                  successMessage(
                                    "Congratulations",
                                    "مبرووووك الهدية",
                                    loadReq
                                  );
                                  dispatch(
                                    generateHashcode({
                                      mobileNumber: mobileNumber,
                                    })
                                  );
                                  navigate("/Customer/Gifts");
                                }
                              });
                            }}
                          >
                            {({
                              values,
                              errors,
                              handleSubmit,
                              isSubmitting,
                            }) => (
                              <Form onSubmit={handleSubmit}>
                                <div className="d-grid">
                                  <Button
                                    type="submit"
                                    variant="danger"
                                    className={`${styles.okBtn} mb-2 mb-md-0`}
                                    disabled={
                                      receiptFile === null
                                        ? true
                                        : false || isSubmitting
                                    }
                                  >
                                    تمام ... ابعت الهدية
                                  </Button>
                                </div>
                              </Form>
                            )}
                          </Formik>
                        </Col>
                        <Col xs={12} md={6}>
                          <div className="w-100">
                            <Link
                              to={`//m.me/ShareeCoin`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className={`${styles.okBtn} w-100 btn btn-sm btn-secondary`}
                            >
                              الدعم الفني
                            </Link>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                </Col>
                <Col
                  xs={12}
                  lg={6}
                  className={`${styles.showReceipt} p-3 my-2 d-flex justify-content-center align-items-center`}
                >
                  {croppedImage ? (
                    <img
                      src={croppedImage}
                      alt="receipt-pic"
                      className={`${styles.receiptPic} img-preview text-center py-2 px-3`}
                    />
                  ) : (
                    <MdOutlinePhotoLibrary
                      className={`${styles.receiptIcon}`}
                    />
                  )}
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
      <Capture
        show={captureModalShow}
        onHide={() => {
          setCaptureModalShow(false);
        }}
      />
      <OtpForm
        show={showOtpModal}
        onHide={() => {
          setShowOtpModal(false);
        }}
      />
    </div>
  );
}
