import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import customerReducer from "./features/customerSlice";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};

const fitReducer = combineReducers({
  customer: customerReducer,
});
const persistedReducer = persistReducer(persistConfig, fitReducer);

const store = configureStore({
  reducer: {
    fit: persistedReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

let persistor = persistStore(store);

export {store,persistor};
