import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./Home.module.scss";
import { Formik } from "formik";
import * as yup from "yup";
import {
  clearResults,
  generateHashcode,
  handleEgiftStatusReceipt,
  handleTrainerImage,
  verifyMobileNumber,
  handleEgiftStatusInfo,
} from "../../../redux/features/customerSlice";
import { useDispatch } from "react-redux";
import { toast, Zoom } from "react-toastify";
import Cookies from "js-cookie";
import successMessage from "../../messages/Successful";
import failedMessage from "../../messages/Failed";

export default function Home() {
  const { trainerName } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const verifyValidation = yup.object().shape({
    mobileNumber: yup
      .string()
      .required("رقم الموبايل مطلوب")
      .matches(/^01[0125][0-9]{8}$/gm, "رقم الموبايل غير صحيح"),
  });

  useEffect(() => {
    dispatch(clearResults());
    Cookies.remove("first-name");
    Cookies.remove("last-name");
    Cookies.remove("email");
    Cookies.remove("mobile-number");
    Cookies.remove("gender");
  }, []);

  useEffect(() => {
    axios
      .get(
        "https://hook.eu1.make.com/wjvdoaeks2jkid1ypfquh7hl6jr9llu2",
        {
          params: {
            trainer: trainerName,
            Function: "getTrainerImageURL",
          },
        },
        {
          headers: {
            "Access-Control-Allow-Origin": true,
          },
        }
      )
      .then((res) => {
        if (!res.data["Trainer ID"]) {
        } else {
          dispatch(
            handleTrainerImage({
              trainerImage: res.data["Trainer Image URL"],
              trainerName: res.data["Trainer Name"],
              trainerID: res.data["Trainer ID"],
            })
          );
        }
      });
  }, []);

  const convertNumbers2English = (string) => {
    return string
      .replace(/[\u0660-\u0669]/g, function (c) {
        return c.charCodeAt(0) - 0x0660;
      })
      .replace(/[\u06f0-\u06f9]/g, function (c) {
        return c.charCodeAt(0) - 0x06f0;
      });
  };

  return (
    <div className={`${styles.homeBackground}`}>
      <Container>
        <Row className="">
          <Col
            xs={{ span: 10, offset: 1 }}
            sm={{ span: 10, offset: 1 }}
            md={{ span: 6, offset: 3 }}
            className={`${styles.customerHome} p-3`}
          >
            <Formik
              initialValues={{ mobileNumber: "" }}
              onSubmit={(values) => {
                const mobileNumberEn = convertNumbers2English(
                  values.mobileNumber
                );
                const loadReq = toast.loading("جاري التحميل", {
                  transition: Zoom,
                  position: "bottom-right",
                });
                dispatch(
                  verifyMobileNumber({ mobileNumber: mobileNumberEn })
                ).then((res) => {
                  if (res.payload.data.statusCode === 200) {
                    successMessage(
                      res.payload.data.message,
                      res.payload.data.messageAr,
                      loadReq
                    );
                    if (res.payload.data["eGift Status"] === 3) {
                      dispatch(
                        handleEgiftStatusReceipt({
                          egiftCode: res.payload.data["eGift code64"],
                        })
                      );
                      dispatch(
                        generateHashcode({
                          mobileNumber: values.mobileNumber,
                        })
                      );
                      navigate("/Customer/Gifts");
                    } else if (res.payload.data["eGift Status"] === 2) {
                      dispatch(
                        handleEgiftStatusInfo({
                          giftValue: res.payload.data["eGift Value"],
                          giftName: res.payload.data["eGift Name"],
                          giftImage: res.payload.data["eGift Image URL"],
                          mobileNumber:
                            res.payload.data["customer mobilenumber"],
                        })
                      );
                      navigate("/Customer/Congrats");
                    } else if (res.payload.data["eGift Status"] === 1) {
                      navigate("/Customer/Choose");
                    }
                  } else {
                    failedMessage(
                      res.payload.data.message,
                      res.payload.data.messageAr,
                      loadReq
                    );
                  }
                });
              }}
              validationSchema={verifyValidation}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleSubmit,
                isSubmitting,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <Form.Group className="mb-3">
                    <Form.Label>رقم موبايلك</Form.Label>
                    <Form.Control
                      type="tel"
                      maxLength={11}
                      placeholder="ادخل رقم موبايلك"
                      className={`${styles.mobileNumber}`}
                      name="mobileNumber"
                      id="mobileNumber"
                      onKeyPress={(e) => {
                        var char = String.fromCharCode(e.which);
                        if (!/[0-9]/.test(char)) {
                          e.preventDefault();
                        }
                      }}
                      onChange={handleChange}
                      value={values.mobileNumber}
                    />
                    {errors.mobileNumber && touched.mobileNumber && (
                      <Form.Text className="text-error">
                        {errors.mobileNumber}
                      </Form.Text>
                    )}
                  </Form.Group>
                  <div className="d-grid">
                    <Button
                      variant="danger"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      تاكيد رقم الموبايل
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
