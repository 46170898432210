import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";

export const verifyMobileNumber = createAsyncThunk(
  "customer/verify",
  async (data, { rejectWithValue }) => {
    try {
      const result = await axios.get(
        "https://hook.eu1.make.com/wjvdoaeks2jkid1ypfquh7hl6jr9llu2",
        {
          params: {
            mobilenumber: data["mobileNumber"],
            Function: "VerifyGift",
          },
        }
      );
      return result;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const customerInfo = createAsyncThunk(
  "customer/info",
  async (info, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        "https://hook.eu1.make.com/wjvdoaeks2jkid1ypfquh7hl6jr9llu2",
        {
          params: {
            firstname: info["firstName"],
            lastname: info["lastName"],
            gender: info["gender"],
            mobilenumer: info["mobileNumber"],
            email: info["email"],
            Function: "sendOTP",
          },
        }
      );
      return response;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const acceptOTP = createAsyncThunk(
  "customer/accept/otp",
  async (info, { rejectWithValue }) => {
    try {
      const result = await axios.get(
        "https://hook.eu1.make.com/wjvdoaeks2jkid1ypfquh7hl6jr9llu2",
        {
          params: {
            mobilenumber: info["mobileNumber"],
            OTPCode: info["otp"],
            "eGift card name": info["giftName"],
            "card value": info["gValue"],
            vendor: "MAX MUSCLE",
            "Trainer ID": info["trainerId"],
            Function: "chooseEgift",
          },
        }
      );
      return result;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const verifyReceipt = createAsyncThunk(
  "verify/receipt",
  async (data, { rejectWithValue }) => {
    const receiptData = new FormData();
    receiptData.append("receipt image", data["receiptFile"]);
    receiptData.append("Customer Mobilenumber", data["mobileNumber"]);
    receiptData.append("Voucher Name", data["giftName"]);
    receiptData.append("Voucher Value", data["giftValue"]);
    receiptData.append("Merchant Name", "MAX MUSCLE");
    receiptData.append("Function", "OrderVoucher");
    try {
      const res = await axios.post(
        "https://hook.eu1.make.com/wjvdoaeks2jkid1ypfquh7hl6jr9llu2",
        receiptData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return res;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const generateHashcode = createAsyncThunk(
  "customer/generate/hashcode",
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        "https://hook.eu1.make.com/wjvdoaeks2jkid1ypfquh7hl6jr9llu2",
        {
          params: {
            mobilenumber: data["mobileNumber"],
            Function: "generateHashcode",
          },
        }
      );
      return response;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

export const changeGift = createAsyncThunk(
  "customer/change/gift",
  async (data, { rejectWithValue }) => {
    try {
      const result = await axios.get(
        "https://hook.eu1.make.com/wjvdoaeks2jkid1ypfquh7hl6jr9llu2",
        {
          params: {
            "eGift card name": data["giftName"],
            "card value": data["gValue"],
            mobilenumber: data["mobileNumber"],
            Function: "cancelGift",
          },
        }
      );
      return result;
    } catch (error) {
      rejectWithValue(error.message);
    }
  }
);

const initialState = {
  trainer: {
    img: "",
    name: "",
    id: "",
  },
  receipt: {
    croppedImageURL: "",
    ReceiptFileImage: null,
    receiptCapturedImage: "",
  },
  gift: {
    value: "",
    name: "",
    type: "",
    image: "",
    barCode: "",
    hashCode: "",
  },
  info: {
    mobileNumber: "",
    otp: "",
    count: 0,
    status: false,
  },
};

export const customerSlice = createSlice({
  name: "customer",
  initialState,
  extraReducers: {
    [verifyMobileNumber.fulfilled]: (state, action) => {
    },
    [customerInfo.fulfilled]: (state, action) => {
      if (action.payload.data.statusCode === 200) {
        state.info.mobileNumber = action.meta.arg.mobileNumber;
        state.info.otp = action.payload.data["OTP code"];
        state.info.count = 0;
      }
    },
    [verifyReceipt.fulfilled]: (state, action) => {
      if(action.payload.data.statusCode !== 403){
        state.gift.barCode = action.payload.data["Coupon code64"];
      }
    },
    [generateHashcode.fulfilled]: (state, action) => {
      state.gift.hashCode = action.payload.data["Customer Hashcode64"];
    },
    [changeGift.fulfilled] : (state,action)=>{
      state.gift.value= "";
      state.gift.name = "";
    }
  },
  reducers: {
    handleCaptureReceipt: (state, action) => {
      state.receipt.receiptCapturedImage = action.payload.capturedImage;
    },
    handleReceiptFileImage: (state, action) => {
      state.receipt.ReceiptFileImage = action.payload.receiptFile;
    },
    handleResetCapturedImage: (state, action) => {
      state.receipt.receiptCapturedImage = "";
    },
    handleTrainerImage: (state, action) => {
      state.trainer.img = action.payload.trainerImage;
      state.trainer.name = action.payload.trainerName;
      state.trainer.id = action.payload.trainerID;
    },
    handleGiftValue: (state, action) => {
      state.gift.value = action.payload.giftValue;
    },
    handleGiftName: (state, action) => {
      state.gift.name = action.payload.giftName;
      state.gift.type = action.payload.giftType;
      state.gift.image = action.payload.giftImage;
    },
    handleOTPCounter: (state, action) => {
      state.info.count = action.payload.count + 1;
    },
    handleOTPencrypt: (state, action) => {
      state.info.otp = action.payload.otpCode;
    },
    handleChooseGiftStatus: (state, action) => {
      state.info.status = true;
    },
    handleEgiftStatusReceipt: (state, action) => {
      state.gift.barCode = action.payload.egiftCode;
    },
    handleEgiftStatusInfo: (state, action) => {
      state.gift.value = action.payload.giftValue;
      state.info.mobileNumber = action.payload.mobileNumber;
      state.gift.name = action.payload.giftName;
      state.gift.image = action.payload.giftImage
      state.info.status = true;
      state.info.otp = "1234567";
    },
    clearResults: (state) => {
      state.trainer.img = "";
      state.trainer.name = "";
      state.trainer.id = "";
      state.receipt.croppedImageURL = "";
      state.receipt.ReceiptFileImage = null;
      state.receipt.receiptCapturedImage = "";
      state.gift.value = "";
      state.gift.name = "";
      state.gift.type = "";
      state.gift.image = "";
      state.gift.barCode = "";
      state.gift.hashCode = "";
      state.info.mobileNumber = "";
      state.info.otp = "";
      state.info.count = 0;
      state.info.status = false;
    },
  },
});

export const {
  handleCaptureReceipt,
  handleResetCapturedImage,
  handleCropImage,
  handleTrainerImage,
  handleGiftValue,
  handleGiftName,
  handleOTPCounter,
  handleOTPencrypt,
  handleChooseGiftStatus,
  handleEgiftStatusReceipt,
  clearResults,
  handleReceiptFileImage,
  handleEgiftStatusInfo,
} = customerSlice.actions;
export default customerSlice.reducer;
